import React, { createContext, useContext } from 'react';
import { ForumPermissionsState } from '../types';
import { GroupPermissions } from '../../shared/types';
import useForumStore from '../hooks/useForumStore';
import { useForumExperiments } from './ForumExperimentsContext';

export const ForumPermissionsContext = createContext<ForumPermissionsState | undefined>(undefined);

export const useForumPermissions = (): ForumPermissionsState => {
  const resource = useContext(ForumPermissionsContext);
  if (!resource) {
    throw new Error('useForumPermissions must be used within a ForumPermissionsProvider');
  }
  return resource;
};

export type ForumPermissionsProviderProps = {
  permissions: GroupPermissions;
  isGroupMember: boolean;
  children: React.ReactNode;
};

export function ForumPermissionsProvider({
  children,
  permissions,
  isGroupMember
}: ForumPermissionsProviderProps): JSX.Element {
  const userId = useForumStore.use.userId();
  const { notificationsExperimentConfig } = useForumExperiments();

  const canDeletePost = (authorId: number) => {
    return (
      (permissions.groupForumsPermissions.createPosts && authorId === userId) ||
      permissions.groupForumsPermissions.removePosts
    );
  };

  const canDeleteComment = (authorId: number) => {
    return (
      (permissions.groupForumsPermissions.createComments && authorId === userId) ||
      permissions.groupForumsPermissions.removeComments
    );
  };

  const canEditPost = (authorId: number) => {
    return permissions.groupForumsPermissions.createPosts && authorId === userId;
  };

  const canEditComment = (authorId: number) => {
    return permissions.groupForumsPermissions.createComments && authorId === userId;
  };

  const canSubscribe = (authorId: number) => {
    return (
      authorId !== userId &&
      !Number.isNaN(userId) &&
      userId !== 0 &&
      !!notificationsExperimentConfig?.receiveSubscriberNotifications // Users not in the notifications treatment should not see a subscribe button
    );
  };

  const canBlockUser = (authorId: number) => {
    return authorId !== userId;
  };

  return (
    <ForumPermissionsContext.Provider
      value={{
        canCreatePost: permissions.groupForumsPermissions.createPosts,
        canCreateComment: permissions.groupForumsPermissions.createComments,
        canPinPost: permissions.groupForumsPermissions.pinPosts,
        canLockPost: permissions.groupForumsPermissions.lockPosts,
        canEditPost,
        canEditComment,
        canDeletePost,
        canDeleteComment,
        canReact: isGroupMember,
        canSubscribe,
        canBlockUser,
        isGroupMember
      }}>
      {children}
    </ForumPermissionsContext.Provider>
  );
}
