import React from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupsConfig } from '../translation.config';
import ConditionalTooltip from '../../shared/components/ConditionalTooltip';
import { useForumPermissions } from '../contexts/ForumPermissionsContext';

export type CreatePostButtonProps = {
  label: string;
  disabled?: boolean;
  onClick: (() => void) | (() => Promise<void>);
} & WithTranslationsProps;

const CreatePostButton = ({
  label,
  disabled,
  onClick,
  translate
}: CreatePostButtonProps): JSX.Element => {
  const { isGroupMember } = useForumPermissions();

  let tooltipContent;
  if (disabled) {
    if (isGroupMember) {
      tooltipContent = translate('Description.NoPostPermission');
    } else {
      tooltipContent = translate('Description.JoinCommunityFirst');
    }
  }

  const buttonId = 'create-post-button';
  const tooltipId = `${buttonId}-tooltip`;

  return (
    <ConditionalTooltip
      id={tooltipId}
      content={tooltipContent}
      placement='top'
      enabled={!!tooltipContent}>
      <button
        id={buttonId}
        type='button'
        className='group-forums-create-post-btn btn-growth-md'
        onClick={onClick}
        disabled={disabled}
        aria-describedby={tooltipContent ? tooltipId : undefined}
        aria-label={label}
        tabIndex={disabled ? -1 : 0}>
        <span className='icon-plus' aria-hidden='true' />
        {label}
      </button>
    </ConditionalTooltip>
  );
};

CreatePostButton.displayName = 'CreatePostButton';

export default withTranslations(CreatePostButton, groupsConfig);
